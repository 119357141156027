import React, { useEffect } from 'react'
import { contactUs } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import { Container, Col, Row } from 'react-bootstrap';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ContactForm from '../components/ContactForm'

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{contactUs.title}</title>
                <meta name="description" content={contactUs.description} />
                <link rel="canonical" href={contactUs.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>Contact Us</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>Contact Us</li>
                    </ul>
                </div>
            </section>
            <section className="entry">
                <Container>
                    <Row>
                        <Col md={4} xs={12} className="contact-info my-auto">
                            <div className="icon mt-unset"><PhoneIcon style={{ color: "#FF690C" }} /></div>
                            <h4>Call</h4>
                            <p>
                                <a href="tel:+91 8866027018">+91 8866027018</a>
                                <br />
                                <a href="tel:+91 7048844119">+91 7048844119</a>
                            </p>
                            <p></p>
                            <div className="icon"><EmailIcon style={{ color: "#FF690C" }} /></div>
                            <h4>Email</h4>
                            <p><a href="mailto:info@snconstruction.com">info@snconstruction.com</a></p>
                            <div className="icon"><RoomIcon style={{ color: "#FF690C" }} /></div>
                            <h4>Address</h4>
                            <p>219, Pramukh Square, Opp. Regenta Resort, <br /> Airport Ring Road, Bhuj - Kachchh, <br /> Gujarat - 370001</p>
                        </Col>
                        <Col md={8}>
                            <ContactForm />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='map mb-5'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.190884151977!2d69.64248817559198!3d23.236139908324134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39511fd42d7cdf8b%3A0xc9bcd3bbdf378619!2sPramukh%20Square!5e0!3m2!1sen!2sin!4v1706609296459!5m2!1sen!2sin" title={'Map Location'} width="100%" height="500" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </section>
        </div>
    )
}

export default ContactUs
