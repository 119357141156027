// Core
import React, { useEffect } from 'react'
import { homepage } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom'
import Slider from '../components/Slider'
import ContactSection from '../components/ContactSection'
import MissionAndVision from '../components/MissionAndVision'
import { Container, Col, Row } from 'react-bootstrap'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Home1 from '../assets/images/home-1.png'
import Home2 from '../assets/images/home-2.png'
import Home3 from '../assets/images/home-3.png'
import Vehicle1 from '../assets/images/vehicle-1.svg'
import Image2 from '../assets/images/image-2.png'
import Design1 from '../assets/images/design-1.svg'
import GetInTouch from '../components/GetInTouch'
import ProjectsComplete from '../components/ProjectsComplete';
import SliderProjects from '../components/SliderProjects'
import OurTeam from '../components/OurTeam';
import SliderProjectsOngoing from '../components/SliderProjectsOngoing'
import LatestProjects from '../components/LatestProjects';
import Marquee from "react-fast-marquee";

const Homepage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{homepage.title}</title>
                <meta name="description" content={homepage.description} />
                <link rel="canonical" href={homepage.canonical} />
            </MetaTags>
            <Slider />
            <section id='about' className="entry section-bg bg-1">
                <Container>
                    <Row>
                        <Col md={6} className='text-center'>
                            <img src={Home1} alt="" className="img-fluid bg" />
                        </Col>
                        <Col md={6} className='d-flex justify-content-center flex-column '>
                            <h2 >About <span>SN Construction</span></h2>
                            <div className="content">
                                <p>SN Construction, a leading construction company that is revolutionising the meaning of excellence in the sector. Our goal as a progressive construction company is to promote a new wave of construction methods that go beyond conventional wisdom.</p>
                                <p> We will never waver in our unwavering commitment to meeting project deadlines, safety, and environmental consciousness.</p>
                                <p> Our conviction at SN Construction is that the foundations we painstakingly lay today are the first steps towards creating a sustainable future.</p>
                            </div>
                            <Link to="/about-us" className="link-primary">Read more <ArrowRightAltIcon /></Link>
                        </Col>
                    </Row>
                    <Row className='mt-md-5'>
                        <LatestProjects />
                    </Row>
                    <Row className='mt-md-5 pb-5'>
                        <Col md={6}>
                            <h2>Why <span>Choose Us ?</span></h2>
                            <Row className='d-flex flex-column why-choose'>
                                <Col className='d-flex text-justify flex-column mt-5'>
                                    <h4><CheckCircleIcon fontSize='large' style={{ color: '#F6911E' }} /> Safety First</h4>
                                    <p>At SN Construction, safety is the cornerstone of everything we do. We prioritize the well-being of our team, clients, and the wider community, ensuring that every construction activity is executed with the highest standards of precaution and care.</p>
                                </Col>
                                <Col className='d-flex text-justify flex-column'>
                                    <h4><CheckCircleIcon fontSize='large' style={{ color: '#F6911E' }} /> Environmental Responsibility</h4>
                                    <p>We embrace our duty to the environment by integrating sustainable practices into every project. From conscientious material choices to energy-efficient construction methods, we strive to minimize our ecological impact and contribute positively to the planet.</p>
                                </Col>
                                <Col className='d-flex text-justify flex-column'>
                                    <h4><CheckCircleIcon fontSize='large' style={{ color: '#F6911E' }} /> Timely Excellence</h4>
                                    <p>Punctuality is a hallmark of our commitment to clients. We understand the significance of delivering projects on or before specified deadlines. Our dedicated team works diligently to ensure that each project is completed with efficiency, meeting and exceeding client expectations.
                                    </p>
                                </Col>
                                <Col className='d-flex text-justify flex-column'>
                                    <h4><CheckCircleIcon fontSize='large' style={{ color: '#F6911E' }} /> Innovation and Quality</h4>
                                    <p>SN Construction thrives on innovation. We consistently explore and implement cutting-edge construction techniques, ensuring that our projects not only meet but surpass industry standards. Our dedication to quality craftsmanship guarantees structures that endure, embodying the spirit of innovation and durability.</p>
                                </Col>

                            </Row>
                        </Col>
                        <Col md={6} className='d-flex justify-content-center align-items-center'>
                            <img src={Home2} alt="About" className="img-fluid bg" />
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee> */}
            <Container fluid className='mt-5'>
                <OurTeam />
            </Container>
            <section className='mt-5 pt-sm-5'>
                <GetInTouch />
            </section>
            <Container fluid className='mt-5'>
                <MissionAndVision />
            </Container>
            <section className="counts section-bg">
                <Container fluid>
                    <Row className='justify-content-center'>
                        <Col md={6} className='my-auto'>
                            <Container>
                                <Row>
                                    <Col md={4} xs={6}>
                                        <div className="card-count type-1">
                                            <span>10+</span>
                                        </div>
                                        <h5>Years of Experience</h5>
                                    </Col>
                                    <Col md={4} xs={6}>
                                        <div className="card-count type-2">
                                            <span>50+</span>
                                        </div>
                                        <h5>Machineries</h5>
                                    </Col>
                                    <Col md={4} xs={6}>
                                        <div className="card-count type-1">
                                            <span>34+</span>
                                        </div>
                                        <h5>Projects</h5>
                                    </Col>

                                </Row>
                            </Container>
                        </Col>
                        <Col md={4} className='img-area'>
                            <img src={Design1} alt="" className="design-2" />
                            <div className="text-end">
                                <img src={Home3} alt="" className="img-fluid image-2" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container fluid className='mt-5'>
                <ProjectsComplete />
            </Container>
            <Container className='mt-5'>
                <div className="text-center">

                    <h2>Our <span>Projects</span></h2><br />
                    <p>Our Art Of Work</p>
                </div>
            </Container>
            <Container fluid>
                <SliderProjectsOngoing />
            </Container>
            <ContactSection />
        </div>
    )
}

export default Homepage
