import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle2 from '../assets/images/vehicle-2.svg'
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Marquee from "react-fast-marquee";

export default function ContactSection() {
    return (
        <section id='contact' className='section-bg'>
            <Container>
                <Row>
                    <Col md={6}>
                        <h2>Contact <span>Us</span></h2><br />
                        <p>We Are Just A Click Away</p>
                        <div className="contacts">
                            <div class="icon">
                                <PhoneIcon />
                            </div>
                            <a href="tel:+91  8866027018">+91 8866027018</a><br />
                            <a href="tel:+91 7048844119">+91 7048844119</a>
                        </div>
                        <div className="contacts">
                            <div class="icon">
                                <EmailIcon />
                            </div>
                            <a href="mailto:info@snconstruction.com">info@snconstruction.com</a>
                        </div>
                        <div className="contacts">
                            <div class="icon">
                                <RoomIcon />
                            </div>
                            <p>219, Pramukh Square, Opp. Regenta Resort, <br /> Airport Ring Road, Bhuj - Kachchh, <br /> Gujarat - 370001</p>
                        </div>
                    </Col>
                    <Col md={6}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.190884151977!2d69.64248817559198!3d23.236139908324134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39511fd42d7cdf8b%3A0xc9bcd3bbdf378619!2sPramukh%20Square!5e0!3m2!1sen!2sin!4v1706609296459!5m2!1sen!2sin" title={'Map Location'} width="100%" height="400" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                </Row>
            </Container>
            <Marquee direction="left" gradientWidth={10}>
                <img src={Vehicle2} alt="Vehicle" />
            </Marquee>
        </section>
    )
}
