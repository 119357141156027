import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Value1 from '../assets/images/value-1.svg'
import Value2 from '../assets/images/value-2.svg'
import Vision1 from '../assets/images/vision-1.svg'
import Vision2 from '../assets/images/vision-2.svg'
import Commitment1 from '../assets/images/commitment-1.svg'
import Commitment2 from '../assets/images/commitment-2.svg'

export default function MissionAndVision() {
    return (
        <Container className="mission" >
            <div className="text-center">
                <h2 className='center'>Our <span>Mission &amp; Vision</span></h2><br />
                <p>What makes us the best choice</p>
            </div>
            <Row>
                <Col md={4} className="mission-row">
                    <div className="card-mission-1">
                        <table>
                            <tr>
                                <td>
                                    <div className="icon-box">
                                        <img src={Commitment1} alt="Commitment" className="or-1" />
                                        <img src={Commitment2} alt="Commitment" className="hover-1" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <h3>Our Mission</h3>
                        <p>Elevating the construction industry, SN Construction integrates safety, environmental responsibility, and timely project completion into every operation. Our commitment to setting new excellence standards ensures structures that reflect a safer and more sustainable world.
                        </p>
                    </div>
                </Col>
                <Col md={4} className="mission-row">
                    <div className="card-mission-2">
                        <table>
                            <tr>
                                <td>
                                    <div className="icon-box">
                                        <img src={Value1} alt="Values" className="or-2" />
                                        <img src={Value2} alt="Values" className="hover-2" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <h3>Our Values</h3>
                        <p>At SN Construction, we prioritize safety, environmental responsibility, and punctual excellence, ensuring high standards, minimal ecological impact, and quality projects. Our success relies on innovation and quality craftsmanship, yielding enduring excellence.
                        </p>
                    </div>
                </Col>
                <Col md={4} className="mission-row">
                    <div className="card-mission-3">
                        <table>
                            <tr>
                                <td>
                                    <div className="icon-box">
                                        <img src={Vision1} alt="Vision" className="or-3" />
                                        <img src={Vision2} alt="Vision" className="hover-3" />
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <h3>Our Vision</h3>
                        <p>We envision a future where construction embodies safety, environmental stewardship, and reliability. As the preferred construction partner for discerning clients, SN Construction leads with innovative, eco-friendly practices, reshaping the industry landscape.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
