// Core
import React from "react";
// Library
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
// Pages
import Homepage from "./pages/Homepage";
import AboutUs from "./pages/AboutUs";
import Projects from "./pages/Projects";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYou from "./pages/ThankYou";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/thank-you" element={<ThankYou />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
