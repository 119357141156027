import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Img1 from '../assets/images/project-1.png'
import Img2 from '../assets/images/project-2.png'
import Img3 from '../assets/images/project-3.png'

export default function LatestProjects() {
    return (
        <section className="mission ">
            <Container>
                <div className="text-center">
                    <h2 className='center'>Growing a  <span>Greener Tomorrow</span></h2><br />
                    <p> At SN Construction, we believe in cultivating a sustainable future. For every customer who chooses our services, we are committed to planting a tree or plant, contributing to the restoration of our environment. Together, we are sowing the seeds of positive change, one customer at a time. Join us in making a lasting impact as we grow a greener tomorrow, hand in hand with our valued customers.</p>
                </div>
                <Row className='mt-5'>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-1">
                            <img src={Img1} alt="Project Image" className='img-fluid' />
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-2">
                            <img src={Img2} alt="Project Image" className='img-fluid' />
                        </div>
                    </Col>
                    <Col md={4} className="mission-row">
                        <div className="card-mission-3">
                            <img src={Img3} alt="Project Image" className='img-fluid' />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
