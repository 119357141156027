import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Value1 from '../assets/images/value-1.svg'
import Value2 from '../assets/images/value-2.svg'
import Vision1 from '../assets/images/vision-1.svg'
import Vision2 from '../assets/images/vision-2.svg'
import Commitment1 from '../assets/images/commitment-1.svg'
import Commitment2 from '../assets/images/commitment-2.svg'

export default function ProjectsComplete() {
    return (
        <Container className="mission" >
            <div className="text-center">
                <h2 className='center'><span>Projects</span> Completed  </h2><br />
                {/* <p>What makes us the best choice</p> */}
            </div>
            <Row className='d-flex justify-content-center gx-5'>

                <Col md={3} className="mission-row">
                    <div className="card-mission-1">

                        <h3>Road Work AT Lakhpat Taluka</h3>
                        <p className='text-center'>Complate In 2K19</p>
                        <p className='text-center'>Company: SUZLON</p>
                    </div>
                </Col>
                <Col md={3} className="mission-row">
                    <div className="card-mission-1">

                        <h3>Road Work AT Nakhatrana Taluka</h3>
                        <p className='text-center'>Complate In 2K20</p>
                        <p className='text-center'>Company: LINOX</p>
                        <p className='text-center'>And Also Dayapar Project</p>
                    </div>
                </Col>
                <Col md={3} className="mission-row">
                    <div className="card-mission-1">

                        <h3>Back Filling Work AT Smart City Dholera</h3>
                        <p className='text-center'>Compate In 2K21</p>
                        <p className='text-center'>Company: KRUNAL CONSTRUCTION
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center gx-5 mt-3'>
                <Col md={3} className="mission-row">
                    <div className="card-mission-1">

                        <h3>Wind Foundation & Excavation Work</h3>
                        <p className='text-center'>NTPC PROJECT IN INOX WIND</p>
                        <p className='text-center'>Dayapar Project KUTCH</p>
                    </div>
                </Col>
                <Col md={3} className="mission-row">
                    <div className="card-mission-1">

                        <h3>Solar Work Successfully Completed</h3>
                        <p className='text-center'>AT KEC INT RE PARK KHAVDA</p>
                    </div>
                </Col>
            </Row>
        </Container >
    )
}
