import React from 'react'
// Library
import { Col, Container, Row } from 'react-bootstrap'
import Team1 from '../assets/images/team-1.jpeg';
import Team2 from '../assets/images/team-2.png';
import Team3 from '../assets/images/team-3.jpeg';
import Team4 from '../assets/images/team-4.jpg';

const OurTeam = () => {

    return (
        <Container>
            <div className="text-center">
                <h2 className='center'>Our <span>Team</span></h2><br />
                <p>What makes us the best choice</p>
            </div>
            <Row className='justify-content-around'>
                <Col md={3}>
                    <div className="project-single team  mt-5">
                        <img src={Team1} alt="Project" className="img-fluid" />
                        <h4>Mr. Swarupsinh Narsinh Sodha<br /><br />Managing Director </h4>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="project-single team mt-5">
                        <img src={Team2} alt="Project" className="img-fluid" />
                        <h4>Mr. Nathusinh Lalji Sodha <br /><br />Managing Director </h4>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="project-single team  mt-5">
                        <img src={Team3} alt="Project" className="img-fluid" />
                        <h4>Mr. Nareshkumar Devji Suthar <br /><br />CEO </h4>
                    </div>
                </Col>
                <Col md={3}>
                    <div className="project-single team  mt-5">
                        <img src={Team4} alt="Project" className="img-fluid" />
                        <h4>Mr. Chandansinh Sonji Sodha <br /><br />Chief Financial Officer </h4>
                    </div>
                </Col>
            </Row>
        </Container >
    )
}

export default OurTeam
