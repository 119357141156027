// Core
import React, { useEffect, useState } from 'react'
import { useLocation, Link } from "react-router-dom";
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RoomIcon from '@mui/icons-material/Room';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Logo from '../assets/images/logo/logo.png';

const Footer = () => {
    let url = useLocation().pathname;
    const [urlHomepage, setUrlHomepage] = useState('')
    const [urlProjects, seturlProjects] = useState('')
    const [urlContact, setUrlContact] = useState('')
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        navigateTo(url)
        // eslint-disable-next-line
    }, [])

    const navigateTo = (url) => {
        if (url === "/") {
            setUrlHomepage('active');
            seturlProjects('');
            setUrlContact('');
        } else if (url === "/projects") {
            setUrlHomepage('');
            seturlProjects('active');
            setUrlContact('');
        } else if (url === "/contact-us") {
            setUrlHomepage('');
            seturlProjects('');
            setUrlContact('active');
        }
        window.scrollTo(0, 0)
    }

    return (
        <div>
            <div id="footer">
                <Container>
                    <Row>
                        <Col md={3}>
                            <img src={Logo} alt="Logo" className='logo mt-1' />
                            {/* <p>SN Construction Private Limited established in 2020, earlier it was known as M/s. SN Construction.</p> */}
                            <div className="social">
                                <ul>
                                    <li>
                                        <a href="https://www.facebook.com/profile.php?id=61555865703891">
                                            <FacebookIcon />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/s-n-construction">
                                            <LinkedInIcon />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col md={2}>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><h6><Link to="/">Home</Link></h6></li>
                                <li><h6><Link to="/about-us">About Us </Link></h6></li>
                                <li><h6><Link to="/projects">Projects</Link></h6></li>
                                <li><h6><Link to="/contact-us">Contact Us</Link></h6></li>
                                <li><h6><Link to="/privacy-policy">Privacy Policy</Link></h6></li>
                            </ul>
                        </Col>
                        <Col md={3} className='contact'>
                            <h5>Contact Us</h5>
                            <RoomIcon style={{ color: '#F6911E' }} /><p>219, Pramukh Square, Opp. Regenta Resort, <br /> Airport Ring Road, Bhuj - Kachchh, <br /> Gujarat - 370001</p>
                            <LocalPhoneIcon style={{ color: '#F6911E' }} /><p><a href="tel:+91 8866027018">+91 8866027018</a></p>
                            <LocalPhoneIcon style={{ color: '#F6911E' }} /><p><a href="tel:+91 7048844119">+91 7048844119</a></p>
                            <EmailIcon style={{ color: '#F6911E' }} /><p><a href="mailto:info@snconstruction.com">info@snconstruction.com</a></p>
                        </Col>
                        <Col md={4}>
                            <h5>Google Map</h5>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3666.190884151977!2d69.64248817559198!3d23.236139908324134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39511fd42d7cdf8b%3A0xc9bcd3bbdf378619!2sPramukh%20Square!5e0!3m2!1sen!2sin!4v1706609296459!5m2!1sen!2sin" title={'Map Location'} width="100%" height="200" frameborder="0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </Col>
                    </Row>
                </Container>
                <div className="d-block d-sm-none menu-footer">
                    <Row className="p-3">
                        <Col className="b-right">
                            <Link to="/" className={urlHomepage} onClick={() => navigateTo('/')}>
                                Home
                            </Link>
                        </Col>
                        <Col className="b-right">
                            <Link to="/projects" className={urlProjects} onClick={() => navigateTo('/projects')}>
                                Project
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/contact-us" className={urlContact} onClick={() => navigateTo('/contact-us')}>
                                Contact Us
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="copyright text-center">
                <p>&copy; {currentYear} SN Construction Pvt. Ltd. All rights reserved.</p>
            </div>
            <div className="bottom"></div>
        </div>
    )
}

export default Footer
