import React from 'react'
import { Link } from 'react-router-dom';
import { Button, Carousel } from 'react-bootstrap'
import Slider1 from '../assets/images/slider-1.png'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Slider = () => {
    return (
        <div>
            <div className="slider hero">
                <Carousel controls={false} interval={null} indicators={false}>
                    <Carousel.Item>
                        <img className="d-block w-100 hero-img" src={Slider1} alt="" />
                        <Carousel.Caption>
                            <h1>Civil Construction Company in Kutch</h1>
                            <p>We Don't Construct Builing, We develop the Future</p>
                            <Link to="/about-us" className="link-primary ms-3"><Button variant="primary">Know more <ArrowForwardIcon fontSize='small' /></Button></Link>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default Slider
