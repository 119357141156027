export const menu = [
  {
    label: "Home",
    path: "/",
  },
  {
    label: "About Us",
    path: "/about-us",
  },
  {
    label: "Projects",
    path: "/projects",
  },
  {
    label: "Contact Us",
    path: "/contact-us",
  },
];
