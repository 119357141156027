import React, { useEffect } from 'react'
import { aboutUs, business } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import ContactSection from '../components/ContactSection'
import About from '../assets/images/about.png'
import { Container, Col, Row } from 'react-bootstrap'
import Vehicle1 from '../assets/images/vehicle-1.svg'
import MissionAndVision from '../components/MissionAndVision'
import Marquee from "react-fast-marquee";
import About1 from '../assets/images/about-1.png'

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{aboutUs.title}</title>
                <meta name="description" content={aboutUs.description} />
                <link rel="canonical" href={aboutUs.canonical} />
            </MetaTags>
            <section id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <h1>SN Construction Pvt. Ltd.</h1>
                    <ul>
                        <li><a href="./">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </section>
            <section id='about' className="entry section-bg bg-1">
                <Container>
                    <Row>
                        <Col md={6} className='text-center order-2'>
                            <img src={About} alt="" className="img-fluid bg" />
                        </Col>
                        <Col md={6} className='order-md-2 order-1'>
                            <h2>Civil Work
                                <span> Contractor in Kutch</span></h2>
                            <div className="content">
                                <p>SN Construction Private Limited established in 2020, earlier it was known as M/s. SN Construction (Proprietary/2013), has recently completed Seven years in the construction industry.</p>
                                <p>During the journey, we have successfully completed many projects e.g. commercial, residential, Temple, Trust Building, Commercial Building, Commercial Complex a healthcare buildings, education structures, pilgrimage sites etc. </p>
                                <p>We have also completed government structures and many projects under sub-contracting formula. It's time to grow and we are pretty sure about our future because we have strength, dedication and enthusiastic team.</p>
                                <p>As we know “the experience makes man perfect” and our skilled team and management made it possible to stand out in the competitive market. We are also specialized for our on-time resolution, lowest scrap generation, maximum output and execution of architectural development. That's true if you wish to design your future, you need to work on your efﬁciency. During the period we've worked on it to achieve many goals.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-5 pt-md-5">
                        <Row>
                            <Col md={6}>
                                <h2>Zero Compromise on <span>Safety</span></h2>
                                <div className="content">
                                    <p>In our relentless pursuit of safety excellence, SN Construction places paramount importance on comprehensive safety training. Our unwavering commitment to ensuring a secure work environment has resulted in an impressive track record – zero casualties on site.</p>
                                    <p>We prioritize the well-being of our team through rigorous training programs, equipping every member with the knowledge and skills necessary to navigate potential hazards safely. At SN Construction, safety is not just a priority; it's a culture, and our commitment to zero casualties reflects our dedication to fostering a workplace that values the health and security of every individual.</p>
                                </div>
                            </Col>
                            <Col md={6} className='text-center'>
                                <img src={About1} alt="About" className="img-fluid bg" />
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Marquee direction="right" gradientWidth={10}>
                    <img src={Vehicle1} alt="Vehicle" />
                </Marquee>
            </section>
            <Container className='mt-5'>
                <MissionAndVision />
            </Container>
            <Container>
                <ContactSection />
            </Container>
        </div>
    )
}

export default AboutUs
