const defaultTitle =
  "Civil Construction Company Kutch, Gujarat | SN Construction";
const defaultDescription =
  "Transform your construction vision into reality with SN Construction. Leading civil construction company in Kutch, Gujarat. Contact us for a consultation.";
const siteUrl = "https://snconstruction.com/";

export const homepage = {
  title: defaultTitle,
  description: defaultDescription,
  canonical: siteUrl,
};

export const aboutUs = {
  title: "Civil Work Contractor in Kutch, Gujarat | SN Construction",
  description:
    "SN Construction is a trusted civil work contractor in Kutch, Gujarat. Get reliable, top-quality construction services with an expert team and timely completion.",
  canonical: siteUrl + "about-us",
};

export const projects = {
  title: "Our Work | Construction Projects in Kutch | SN Construction",
  description:
    "Discover SN Construction's outstanding construction projects in Kutch, Gujarat. Experience our dedication to quality and innovation firsthand. Visit for more!",
  canonical: siteUrl + "projects",
};

export const contactUs = {
  title: "Contact Us | Civil Contractors in Kutch | SN Construction",
  description:
    "Contact SN Construction, leading civil contractor in Kutch, Gujarat, for expert construction services. Reach out for reliable and professional solutions today!",
  canonical: siteUrl + "contact-us",
};

export const privacyPolicy = {
  title: "Privacy Policy | SN Construction",
  description:
    "SN Construction's privacy policy outlines the types of data we gather, how we use it, and the rights you have over your information. Read our policy.",
  canonical: siteUrl + "privacy-policy",
};

export const thankYou = {
  title: "Thank You | SN Construction",
  description: "Thank You | SN Construction",
  canonical: siteUrl + "thank-you",
};
